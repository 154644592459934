/** @jsx jsx */
import { jsx } from "theme-ui";
import { Layout, Content, Section } from "maker-ui";
import { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";
import Seo from "../components/seo";
import "../../static/styles/dollar.css";
import EcsFormsDownload from "../components/EcsFormsDownload";
import ContentHeader from "../components/campaignComponents/ContentHeader";
import banner from "../../static/imgs/dollarAdayUsaBanner.png";
import OneTimeDonation from "../components/campaignComponents/OneTimeDonation";
import Testimonials from "../components/campaignComponents/Testimonials";
import Slider from "../components/campaignComponents/Slider";
import plantingSaplings from "../../static/imgs/plantingSaplings.png";
import trainingChildren from "../../static/imgs/trainingChildren.png";
import freeworkshop from "../../static/imgs/freeWorkshop.png";
import DonationComp from "../components/campaignComponents/DonateBox";

require("react-datepicker/dist/react-datepicker.css");
require("bootstrap/dist/css/bootstrap.min.css");

library.add(faTimes, faUserPlus);

const impactStories = [
  {
    id: 1,
    title: "Education",
    description:
      "Your donation provides one child with access to the Brighter Minds program, enhancing their cognitive and emotional skills. 1500 INR also supports HELP training for 50 children monthly, fostering an environment where students can excel academically and personally. Through ventures in schools and universities we reach out to every level of the system, from the child to their teachers to their parents with our programs aimed at enhancing the crucial aspects.",
    image: trainingChildren,
    text: "Heartfulness@School",
    color: "#1c76a5",
    link: "https://www.heartfulnessinstitute.org/education/",
    additionalInfo: [
      {
        iconClass: "icon-1",
        title: "10000+ Children",
        desc: "Enrol for Brighter Minds Program",
      },
      {
        iconClass: "icon-2",
        title: "700000+ Students",
        desc: "Participated - Heartful Campus Program",
      },
      {
        iconClass: "icon-3",
        title: "18000+ College Faculty",
        desc: "Participated for INSPIRE programs",
      },
      {
        iconClass: "icon-4",
        title: "50000+ Teachers",
        desc: "INSPIRE: Trained as Heartful Teachers",
      },
    ],
  },
  {
    id: 2,
    title: "Planting Saplings and Transforming the Environment",
    description:
      "Your contribution supports the planting of 10 saplings each month, aiding environmental conservation. These trees absorb carbon dioxide, improve air quality, and provide wildlife habitats. They also beautify urban and rural areas, creating greener, healthier communities. Your support ensures these trees grow and thrive for generations.",
    image: plantingSaplings,
    text: "Forests by Heartfulness",
    color: "#56a51c",
    link: "https://heartfulness.org/forests/",
    additionalInfo: [
      {
        iconClass: "icon-5",
        title: "4 Million+",
        desc: "Saplings Planted",
      },
      {
        iconClass: "icon-6",
        title: "20000+",
        desc: "Farmers Community Supported",
      },
      {
        iconClass: "icon-7",
        title: "25000 Tons",
        desc: "Co2 Offset Annually",
      },
      {
        iconClass: "icon-8",
        title: "10000+ and 4 Lakes",
        desc: "Reforested and Restoration",
      },
    ],
  },
  {
    id: 3,
    title: "Free Workshops on Meditation",
    description:
      "Your support enables free workshops teaching meditative practices, promoting mental well-being and stress reduction. These workshops help individuals manage stress, improve focus, and enhance mental health, contributing to a more mindful society. we conduct Mahotsavs from the local to the international level to help each individual in their journey to move forward towards their destiny.",
    image: freeworkshop,
    text: "Heartfulness@Work",
    color: "#173E5F",
    link: "https://workplace.heartfulnessinstitute.org/",
    additionalInfo: [
      {
        title: "6000+",
        iconClass: "icon-9",
        desc: "Meditation Places",
      },
      {
        title: "280+",
        iconClass: "icon-10",
        desc: "Retreat Centers",
      },
      {
        title: "160+",
        iconClass: "icon-11",
        desc: "Countries",
      },
      {
        title: "10M+",
        iconClass: "icon-12",
        desc: "Practitioners",
      },
      {
        title: "20000+",
        iconClass: "icon-13",
        desc: "Certified Trainers",
      },
    ],
  },
];

const slides = [
  {
    image: "",
    description:
      "I've seen the positive effects of meditation on my students and my own family. This campaign is a fantastic opportunity to extend these benefits to more people. Your donation will help provide access to meditation resources and workshops, making a real difference in many lives.",
    name: "Ananth",
  },
  {
    image: "",
    description:
      "Meditation has been a source of creativity and inspiration in my life. By donating to this campaign, you're helping others discover this powerful tool for unlocking their own potential. It's a beautiful way to support the growth and expression of individuals everywhere",
    name: "Manikandan",
  },
  {
    image: "",
    description:
      "Donating to this campaign was one of the best decisions I've made. This helps individuals find inner peace but also fosters a sense of community. I've seen firsthand how meditation can transform lives, and it's incredible to support a cause that makes such a positive impact.",
    name: "Jayabalan",
  },
  {
    image: "",
    description:
      "As a student juggling multiple responsibilities, meditation has been a lifesaver. It helps me stay centered and manage my anxiety. Contributing to this campaign melens more students can discover these benefits and thrive both academically and personally.",
    name: "Priyanka",
  },
];

const config = {
  currency: "INR",
  donationId: 27,
  titletext: "Every Drop Counts - Donation, Recurring",
  symbol: "₹",
  initialAmount: 1500,
  meditationPlaceField: true,
};

const defaultAmounts = [
  { value: 1500, perday: 50 },
  { value: 3000, perday: 100 },
  { value: 6000, perday: 200 },
];

const EveryDropCountsSmsf = () => {
  const [showDialog, setShowDialog] = useState(false);

  const handleDownload = () => {
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className="campaign-wrapper">
      <Layout theme={theme} options={options}>
        <Seo title="Recurring-India" />
        <HeaderComp headerCustomLogo="/imgs/heartfulness-white-logo.png" />
        <Content>
          <Section>
            <ContentHeader
              banner={banner}
              title="Donate ₹50 a Day"
              subTitle="₹1500 a Month"
            />
            <div className="container">
              <div
                className="row my-5"
                sx={{
                  display: "flex",
                  "@media (max-width: 768px)": {
                    flexDirection: "column-reverse",
                  },
                }}
              >
                <div className="col-lg-6 px-3 my-3">
                  <h2
                    sx={{
                      color: "#1f4b72",
                      fontWeight: "700",
                      textAlign: "center",
                      fontSize: "2.5rem",
                      "@media (max-width: 768px)": {
                        fontSize: "2.5rem",
                      },
                    }}
                  >
                    Our <span sx={{ color: "rgb(88 153 208)" }}>Mission</span>
                  </h2>
                  <p className="mt-4" sx={{ fontSize: "1rem !important" }}>
                    Heartfulness will always remain free. It is guided by the
                    core principle that divine wisdom cannot be sold. With over
                    10,000 volunteer instructors and a million practitioners
                    worldwide, the Heartfulness Institute runs on the enthusiasm
                    and efforts of each one of us. Whether we share a donation,
                    volunteer our time, or simply stay connected and immersed in
                    the light within—every bit helps contribute to the movement.
                  </p>
                  <p className="mt-4" sx={{ fontSize: "1rem !important" }}>
                    For those who have the means and are looking for a way to
                    give back and pay it forward, <b>₹50</b> per day is a
                    wonderful start!
                  </p>
                </div>
                <div className="col-lg-6 my-3">
                  <DonationComp
                    config={config}
                    defaultAmounts={defaultAmounts}
                  />
                </div>
              </div>
            </div>
            <div
              className="container"
              sx={{
                color: "#2b2b2b",
                fontSize: "18px",
                fontFamily:
                  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
              }}
            >
              <div className="col-12">
                <div sx={{ textAlign: "center" }}>
                  <p
                    sx={{
                      color: "#1f4b72",
                      fontWeight: "700",
                      textAlign: "center",
                      fontSize: "2.5rem",
                      "@media (max-width: 768px)": {
                        fontSize: "2.5rem",
                      },
                    }}
                  >
                    Every{" "}
                    <span style={{ color: "rgb(88 153 208)" }}>
                      {" "}
                      Drop Counts{" "}
                    </span>
                  </p>
                  <p
                    sx={{
                      mb: 4,
                      fontSize: "2rem",
                      color: "rgb(88 153 208)",
                      fontFamily: "home-made-apple",
                      "@media (max-width: 768px)": {
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                    a golden opportunity
                  </p>
                </div>

                <p className="mb-4" style={{ color: "#173E5F" }}>
                  We invite you to support Heartfulness initiatives in alignment
                  with Daaji’s vision. By contributing an amount from a minimum
                  of ₹50 to any figure of your choice on a recurring basis, we
                  can scale up the immense work that is happening all over the
                  world through offering transformative meditation practices,
                  wellness programs, and community outreach activities. Your
                  regular support ensures the sustainability of these
                  initiatives, enabling us to reach more individuals and
                  communities in need.
                </p>
              </div>
            </div>
            <Slider
              heading={
                <h2 className="pb-5 sliders-heading">
                  Impact <span className="highlightText"> Stories</span>{" "}
                </h2>
              }
              impactStories={impactStories}
            />
            <Testimonials slides={slides} noImage />
            <OneTimeDonation text="Want to make a one-time donation?" />
            <div className="my-5 container" style={{ color: "black" }}>
              <p>
                For offline: Physical copies of the ECS donation form are
                available for download{" "}
                <button
                  type="button"
                  sx={{
                    color: "rgb(0, 97, 166)",
                    border: "none !important",
                    background: "none !important",
                    padding: "0 !important",
                    "&:hover": {
                      color: "rgb(40, 139, 208)",
                    },
                  }}
                  onClick={() => handleDownload()}
                >
                  here
                </button>
                .
              </p>
              <p>
                You may email to us at{" "}
                <a href="mailto:info.accounts@sahajmarg.org">
                  info.accounts@sahajmarg.org
                </a>{" "}
                or missed call (India) at +91-89395-89295 or Call toll free
                (india) at 1800-121-3492
              </p>
            </div>
            <EcsFormsDownload
              showDialog={showDialog}
              closeDialog={closeDialog}
            />
          </Section>
        </Content>
        <FooterComp />
      </Layout>
    </div>
  );
};

export default EveryDropCountsSmsf;
